import { render, staticRenderFns } from "./BusinessFocus-View.vue?vue&type=template&id=4de8c7c4&scoped=true&"
import script from "./BusinessFocus-View.vue?vue&type=script&setup=true&lang=js&"
export * from "./BusinessFocus-View.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./BusinessFocus-View.vue?vue&type=style&index=0&id=4de8c7c4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de8c7c4",
  null
  
)

export default component.exports