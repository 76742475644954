<script setup>
// import VerticalBread from '../components/VerticalBread.vue'
import Navigation from '../components/Navigation-Component.vue'
import SaveAllData from '../components/SaveAllData.vue' // Error messages
</script>
<script>
import config from '../config.json';
export default {
  data() {

    return {
      envConfig: config,
      EPPID: "",
      yearlySpend: "An amount",
      showErrorMessage: false,
      ErrorMessage: "There was an error",
      loadingData: false
    }
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {

  },
  methods: {
    unused() {

    }
  },

}
</script>


<template>
  <main class="addAnotherProject">
    <div>
      <!-- Navigation elements -->
      <Navigation page="5" />
    </div>
    <div class="threeColParent">
      <div class="col1">
        <!-- <VerticalBread page="6" /> -->
      </div>
      <div class="col2">
        <br />
        <h1>Would you like to add another project?</h1>

        <div class="twoColButtonsVertical">

          <RouterLink to="/AddThirdProject">
            <button data-v-2917d68d="" class="addChallenge">
              <img data-v-2917d68d="" alt="Add Challenge" class="plusCircle" src="@/assets/images/plus-circle.svg">
              <span data-v-2917d68d="">Add Another Project</span>
            </button>
          </RouterLink>

          <SaveAllData pageName="SaveAddAnotherPage" />

        </div>

      </div>
      <div class="col3">
      </div>
    </div>
    <footer></footer>
  </main>
</template>

<style lang="scss" scoped>
.saveAllDataContainer {
  width: 220px;
}

.twoColButtonsVertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.twoColButtons {
  display: flex;

  a {
    width: 100%;
  }

  a:nth-child(1) {
    margin-right: 20px;
    ;
  }

}

.threeColParent {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  margin: 60px 20px 0 20px;

  .col1 {
    width: 30%;
  }

  .col2 {
    width: 40%;

    h1 {
      width: 100%;
      text-align: center;
    }

    p.companyRoleDetails {
      margin-bottom: 20px;
      ;
    }
  }

  .col3 {
    width: 30%;

    p {
      width: 400px;
    }
  }
}


.loginInput {
  display: flex;
  flex-flow: column;
  align-items: left;
  justify-content: center;

  .label {
    display: inline-block;
  }
}


button.addChallenge .plusCircle {
  top: 6px;
}

button.addChallenge {
  padding: 20px 30px;
  position: relative;
  top: 28px;
}

.logo {
  margin-right: 20%;
}

@media screen and (max-width: 768px) {
  .threeColParent {

    .col1,
    .col3 {
      width: 0%;
    }

    .col2 {
      width: 100%;
    }
  }
}
</style>
