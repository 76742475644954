<script setup>
import "../assets/sass/main.scss"
</script>

<script>
export default {
  props: [
    'page', 
    'lastName'
  ],
}
</script>

<template>
    <!-- Navigation elements -->
    <header>
        <div class="wrapper">
            <a class="logoLink" href="https://gdsgroup.com/" target="_blank">
                <img alt="Vue logo" class="headerLogo" src="@/assets/GDS_logo-light.svg" />
            </a>
            <nav>
                <RouterLink :class="{ makeVisible: Number(page) >0 }" to="/">Login </RouterLink>
                <RouterLink :class="{ makeVisible: Number(page) >0 }" to="/Profile"><span> <p class="angleBracket">  </p>  </span> Profile  </RouterLink>
                <RouterLink :class="{ makeVisible: Number(page) >1 }" to="/BusinessFocus"><span> <p class="angleBracket">  </p>  </span> Business Focus </RouterLink>
                <RouterLink :class="{ makeVisible: Number(page) == 4, 'router-link-exact-active': Number(page) >3 , 'router-link-active': Number(page) >3 }" to="/AddFirstProject"><span> <p class="angleBracket"> > </p>  </span> Projects  </RouterLink>
                <RouterLink :class="{ makeVisible: Number(page) >4 }" to="/AddFirstProject"><span> <p class="angleBracket">  </p>  </span> Projects  </RouterLink>
                <RouterLink :class="{ 'makeVisible router-link-exact-active router-link-active': Number(page) == 5  }" to="/AddAnotherProject"><span> <p class="angleBracket">  </p>  </span> Add Project</RouterLink>
                <RouterLink :class="{ makeVisible: Number(page) >5 }" to="/AddAnotherProject"><span> <p class="angleBracket">  </p>  </span> Add Project</RouterLink>
                <RouterLink :class="{ 'makeVisible router-link-exact-active router-link-active': Number(page) == 6  }" to="/completed"><span> <p class="angleBracket">  </p>  </span> Completed</RouterLink>
    
            </nav>
        </div>
    </header>
</template>

<style lang="scss" scoped>
    header {
        background-color: #0c193c;
        padding-top: 25px;
        .wrapper {
            margin-left: 10%;
            a.logoLink {
                display: contents;
            }
        }
    }


    nav {
        a {
            color: #fff;
            margin-left: 50px;
        }
        a.router-link-exact-active {
            color: #f6901e;
        }
    }

@media only screen and (max-width: 900px) {
    header nav {
        display: none;
    }

}


    .angleBracket {
        color: #000;
        display: inline-block;
    }
    .wrapper {
        display: flex;
    }

    .headerLogo {
        width: 80px;
        top: -15px;
        margin-right: 60px;
        position: relative;
    }

    a.makeVisible span {
        margin: 0 5px;
    }

    // Hide all the nav elements by default
    nav a {
        display:none;
    }
    // If makeVisible class is added the show the nav element this is how the breadcrumbs will work
    nav a.makeVisible {
        display: inline-block;
    }
</style>